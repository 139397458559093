<template>
  <div class="tw-element-surveyprogress">
    <div class="tw-element-surveyprogress-top">
      <div class="tw-element-surveyprogress-buttons">
        <button
          v-if="canBackButtonView"
          class="tw-element-surveyprogress-back"
          @click="goBack"
        >
          <img
            v-if="config.backButton?.icon"
            :src="config.backButton?.icon"
            alt="Arrow Left"
          />
          <span v-if="backButtonText">{{ backButtonText }}</span>
        </button>
      </div>
      <div
        class="tw-element-surveyprogress-status"
        :class="{
          'flex !justify-center': canSkipButtonView
        }"
      >
        <template v-if="config.showPercentage">
          %{{ percentage }}
        </template>
        <template v-else>
          <span>{{ hasLogic ? customCurrentPage : currentPage }}</span>
          <template v-if="!hasLogic">
            /{{ total }}
          </template>
        </template>
      </div>
      <div v-if="canSkipButtonView" class="tw-element-surveyprogress-buttons flex justify-end">
        <button
          v-show="!isAnswerRequired"
          class="tw-element-surveyprogress-skip"
          @click="skipQuestion"
        >
          <span v-if="skipButtonText">{{ skipButtonText }}</span>
          <img
            v-if="config.skipButton?.icon"
            :src="config.skipButton?.icon"
            alt="Arrow Right"
          />
        </button>
      </div>
    </div>

    <div class="tw-element-surveyprogress-bar">
      <span class="tw-element-surveyprogress-bar-current" :style="{ width: `${percentage}%` }"></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { PageOptions, Element as ElementType } from '../../types/model';
import type { ElementOptions } from '../../types/options';
import { globalEmit } from '@shared/utils/helpers';

const props = defineProps({
  showPercentage: { type: Boolean, default: false },
  current: { type: Number, default: 1 },
  total: { type: Number, default: 10 },
  config: { type: Object as PropType<ElementOptions['surveyprogress']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'surveyprogress'>>, default: () => ({}) },
  pageData: { type: Object as PropType<any>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false }
});

const isAnswerRequired = computed(() => props.pageData?.answerRequired);
const hasLogic = computed(() => !!props.pageData?.hasLogic);
const customCurrentPage = computed(() => props.pageData?.customPagination?.history?.length);
const currentPage = computed(() => (props.isEditorMode ? 3 : props.current));
const percentage = computed(() => ((currentPage.value / props.total) * 100).toFixed(0));
const progressStyle = computed(() => {
  return {
    fontFamily: props.element.style.fontFamily || props.pageOptions.fontFamily,
    color: props.element.style.color || props.pageOptions.colors.text[0],
    activeColor: props.config.activeColor || props.pageOptions.colors.theme[0],
    bgColor: props.config.bar?.bgColor,
    trackColor: props.config.bar?.trackColor || props.pageOptions.colors.theme[0]
  };
});
const values = computed(() => JSON.parse(props.element.values || '{}'));
const backButtonText = computed(() => values.value?.back || '');
const skipButtonText = computed(() => values.value?.skip || '');
const canBackButtonView = computed(() => {
  return (
    props.config.backButton?.show && currentPage.value > 1 && (backButtonText.value || props.config.backButton?.icon)
  );
});
const canSkipButtonView = computed(() => {
  return (
    props.config.skipButton?.show &&
    currentPage.value <= props.total &&
    (skipButtonText.value || props.config.skipButton?.icon))
});

function goBack(event: any) {
  globalEmit('goBack', [event]);
}

function skipQuestion(event: any) {
  globalEmit('skipQuestion', [event]);
}
</script>

<style lang="postcss">
.tw-element-surveyprogress {
  --surveyprogress-color: v-bind('progressStyle.color');
  --surveyprogress-fontFamily: v-bind('progressStyle.fontFamily');
  --surveyprogress-activeColor: v-bind('progressStyle.activeColor');
  --surveyprogress-barBgColor: v-bind('progressStyle?.bgColor');
  --surveyprogress-barTrackColor: v-bind('progressStyle?.trackColor');

  color: var(--surveyprogress-color, #000000);
  font-family: var(--surveyprogress-fontFamily);
  padding: 20px;
  padding-bottom: 4px;

  &-buttons {
    flex: 1;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }

  &-back {
    border: 0;
    padding: 0;
    background-color: transparent;
    display: inline-flex;
    align-items: center;

    img {
      width: 14px;
      height: 14px;
      object-fit: contain;
      margin-right: 5px;
    }
  }

  &-skip {
    border: 0;
    padding: 0;
    background-color: transparent;
    display: inline-flex;
    align-items: center;

    img {
      width: 14px;
      height: 14px;
      object-fit: contain;
      margin-left: 5px;
    }
  }

  &-status {
    display: flex;
    justify-content: center;
    text-align: right;

    span {
      color: var(--surveyprogress-activeColor, #008bff);
    }
  }

  &-bar {
    background-color: var(--surveyprogress-barBgColor, #e5e5ea);
    height: 4px;
    border-radius: 2px;
    margin-top: 10px;

    &-current {
      display: block;
      background-color: var(--surveyprogress-barTrackColor, #008bff);
      height: 100%;
      border-radius: 2px;
      transition: width 0.2s ease-in-out;
    }
  }
}

[dir='rtl'] .tw-element-surveyprogress {
  &-buttons {
    flex: 1;
  }

  &-status {
    flex: 1;
    justify-content: end;
  }

  &-back {
    width: 100%;
    justify-content: start;
    img {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  &-skip {
    width: 100%;
    justify-content: flex-end;
    img {
      margin-right: 5px;
      margin-left: 0;
    }
  }
}
</style>
